import React from 'react'
import { Box, Typography } from '@material-ui/core'
// core components
import Roadmap from 'components/Roadmap/Roadmap'
// images
import roadmap1 from 'assets/images/Home/10.gif'
import roadmap2 from 'assets/images/Home/20.gif'
import roadmap3 from 'assets/images/Home/30.gif'
import roadmap4 from 'assets/images/Home/40.gif'
import roadmap5 from 'assets/images/Home/50.gif'
import roadmap6 from 'assets/images/Home/60.gif'
import roadmap7 from 'assets/images/Home/70.gif'
import roadmap8 from 'assets/images/Home/80.gif'
import roadmap9 from 'assets/images/Home/80.gif'
import roadmap10 from 'assets/images/Home/100.gif'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/pages/Home/roadmapSectionStyle'

const useStyles = makeStyles(styles)

const RoadmapSection = () => {
  const classes = useStyles()

  return (
    <Box className={classes.buySectionArea}>
      {/* <Box className={classes.container}> */}
      <Box className={classes.content}>
        <Typography className={clsx(classes.roadmapTitle, classes.title)}>🚦ROADMAP🚦</Typography>
      </Box>
      <Box className={classes.roadmapContainer}>
        <Box style={{ marginLeft: '60px' }}>
          <Roadmap image={roadmap1} percentage={20} description={'Phase 1: Active'} />
          <Typography className={classes.text}>Project Launch</Typography>
          <Typography className={classes.text}>Twitter and Discord giveaways</Typography>
          <Typography className={classes.text}>Private sale (Whitelist)</Typography>
        </Box>
        <Box style={{ marginLeft: '60px' }}>
          <Roadmap
            image={roadmap2}
            percentage={40}
            description={'Phase 2'} />
          <Typography className={classes.text}>Public sale</Typography>
          <Typography className={classes.text}>Discord community refferal/invite perks</Typography>
        </Box>
        <Box style={{ marginLeft: '60px' }}>
          <Roadmap image={roadmap3} percentage={60} description={'Phase 3'} />
          <Typography className={classes.text}>Collaborations</Typography>
          <Typography className={classes.text}>Vote on future events</Typography>
          <Typography className={classes.text}>Community merch TBA</Typography>
        </Box>
        <Box style={{ marginLeft: '60px' }}>
          <Roadmap
            image={roadmap4}
            percentage={80}
            description={'Phase 4'} />
            <Typography className={classes.text}>Dope Skulls utility program "The Collective"</Typography>
            <Typography className={classes.text}>Premium holders get airdrops + early access whitelist to new series</Typography>
            <Typography className={classes.text}>Dope Skulls rolls out to other networks</Typography>
        </Box>
        <Box style={{ marginLeft: '60px' }}>
          <Roadmap image={roadmap5} percentage={100} description={'Phase 5'} />
          <Typography className={classes.text}>Dope Skulls enters the Metaverse</Typography>
        </Box>
      </Box>
      {/* </Box> */}
    </Box>
  )
}

export default RoadmapSection
