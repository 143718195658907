import { containerFluid } from 'assets/jss/constants'

const footerStyle = {
  body: {
    width: '100%',
    // backgroundColor: '#333375 !important',
    backgroundColor: '#FFFFFF !important',
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    marginBottom: '0px',
  },
  container: {
    ...containerFluid,
    '@media (max-width: 768px)': {
      maxWidth: '540px',
    },
    '@media (min-width: 768px)': {
      maxWidth: '640px',
    },
  },
  supportContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  text: {
    color: 'rgba(black)',
    margin: '0px 20px',
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '41px',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: '#cf933a',
    },
  },
  selectedText: {
    color: '#E97800',
    margin: '0px 20px',
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '41px',
    letterSpacing: '0.26px',
    cursor: 'pointer',
  },
  supportImage: {
    alignItems: 'center',
    display: 'flex',
  },
  imageLine: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  socialAvatar: {
    width: '30px',
    height: '30px',
  },
}

export default footerStyle
