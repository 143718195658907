import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
// images
import walletImage from 'assets/images/Home/feature.jpg'
import gameAvatar1 from 'assets/images/Home/yellow_monster.gif'
import gameAvatar2 from 'assets/images/Home/green_monster.gif'
import gameAvatar3 from 'assets/images/Home/pink_monster.gif'
import gameAvatar4 from 'assets/images/Home/brown_monster.gif'
import featureAvatar from 'assets/images/Home/forrest.png'
import clsx from 'clsx'
import { makeStyles, withStyles, TextField, Button } from '@material-ui/core'
import styles from 'assets/jss/pages/Home/buyNowSectionStyle'
import globalStyles from 'assets/jss/PLUTEX'
import toast from 'react-hot-toast'
import { Web3Context } from 'utils/Web3Provider'

const useStyles = makeStyles(styles)
const useGlobalStyles = makeStyles(globalStyles)

const MAX_VALUE = 'Minting Soon!'
const INPUT_VALUE = 'You must input quantity'
const WRONG_NETWORK = 'You should connect to the Ethereum Mainnet'
const SUCCSESS_CONNECTED = 'Successfully connected to the Ethereum Mainnet'
const WAIT_METAMASK = 'Please wait a moment.'
const SUCCESS_BUY = 'Successfully buy'
const ASTRO_PRICE = 0.08 //0.08 ETH

const WalletButton = withStyles(() => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: 'purple',
    },
    fontWeight: '800',
    borderRadius: '25px',
    padding: '10px 15px',
    textTransform: 'none',
    fontFamily: 'sans-serif',
    width: '130px',
    margin: '20px',
  },
}))(Button)

const BuyButton = withStyles(() => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#cf933a',
    },
    fontWeight: '700',
    borderRadius: '25px',
    padding: '10px 15px',
    fontFamily: 'sans-serif',
    // textTransform: 'none',
    width: '130px',
    margin: '20px',
  },
}))(Button)

const BuyNowSection = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const { connectionStatus, notifyLabel, balance, address, walletInstalledStatus, loadWeb3, nftToken } =
    useContext(Web3Context)
  const [quantity, setQuantity] = useState('')
  const [progressStatus, setProgressStatus] = useState(false)

  const handleClickWallet = async () => {
    if (connectionStatus) {
      toast.success(SUCCSESS_CONNECTED)
    }
    await loadWeb3()
  }

  useEffect(() => {
    if (connectionStatus) {
      toast.success(notifyLabel)
    } else {
      if (notifyLabel !== '') {
        toast.error(notifyLabel)
      }
    }
  }, [notifyLabel])

  useEffect(() => {
    if (!walletInstalledStatus)
      window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en', '_blank')
  }, [walletInstalledStatus])

  const handleClickBuy = () => {
    if (quantity === '') {
      toast.error(INPUT_VALUE)
      return
    } else if (quantity > 0 || quantity < 1) {
      toast.error(MAX_VALUE)
      setQuantity('')
      return
    }
    if (!connectionStatus) {
      toast.error(WRONG_NETWORK)
      return
    }
    toast.success(WAIT_METAMASK)
    setProgressStatus(true)
    nftToken.methods
      .mintAstro(quantity)
      .send({ from: address, value: window.web3.utils.toWei((quantity * ASTRO_PRICE).toString()) })
      .then(data => {
        console.log(data)
        if (data.status) {
          toast.success(SUCCESS_BUY)
          setProgressStatus(false)
          setQuantity('')
        }
      })
  }

  const handleChangeQuantity = event => {
    const reg = /^\d+$/
    if (event.target.value === '' || reg.test(event.target.value)) {
      setQuantity(event.target.value)
    }
  }

  return (
    <>
      <Box className={classes.buyNowSectionArea}>
        <Box className={classes.quantityContainer}>
          <Box className={classes.container}>
            <Grid container display="flex" alignItems="center" justify="center">
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" justifyContent="center" mb={5}>
                  <img src={walletImage} className={classes.walletArea} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.titleBottom}>
                  <Typography className={clsx(classes.title, classes.quantityTitle)}>Presale</Typography>
                </Box>
                <Box className={classes.textContentArea}>
                  <Typography className={classes.text31}>Date: TBA</Typography>
                </Box>
                <Box className={classes.textContentArea}>
                  <Box className={classes.text31}>
                    <Typography className={classes.text31} gutterBottom>
                      Price:
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      id="text-quantity"
                      variant="outlined"
                      value={quantity}
                      className={classes.textQuantity}
                      onChange={handleChangeQuantity}
                    />
                  </Box>
                </Box>
                <Box className={classes.textContentArea}>
                  <WalletButton variant="contained" className={classes.wallet} onClick={handleClickWallet}>
                    Link Wallet
                  </WalletButton>
                  <BuyButton
                    variant="contained"
                    className={classes.roadmap}
                    onClick={handleClickBuy}
                    disabled={progressStatus}
                  >
                    MINT
                  </BuyButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.detailContainer}>
          <Box className={classes.container}>
            <Box className={classes.content}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.title}>The Collective</Typography>
                <Typography className={globalClasses.text21}>
                <Typography className={globalClasses.text21}>
              500 fresh to death 💀 on a mission to slay and spread good vibes in the metaverse
              </Typography>Dope Skulls is a collectible NFT project that was created with love with each piece having a unique personality.  
                These non-fungible tokens (NFTs) lives on the blockchain.
                Holding a Dope Skull gives you a chance of earning community perks, enrolment into our utility program and entry into official giveaways. 
                The Dope Skulls community is important so feedback is welcomed in the ongoing development of the project. 
                  <br />
                  <br />
                  <br />
                  <br /> Presale and public sale date TBA! Join the discord and follow our twitter for updates.

                </Typography>
                <Typography variant="h5" className={classes.distribution}>
                  <br />
                  Our Mission 💀
                </Typography>
                <Typography className={globalClasses.text21}>
                  The skulls became unearthed, then given a major makeover.
                  Ditching the cryptonite and ready to slay the Metaverse, you can find a Dope Skull chilling on the blockchain.
                </Typography>

                </Grid>
            </Box>
          </Box>
        </Box>
        <Box className={classes.gameBottomContainer}>{/* <img src={gameBottomImg} /> */}</Box>
      </Box>
    </>
  )
}

export default BuyNowSection
